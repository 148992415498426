<template>
  <b-card
    id="reservation-list"
    no-body
    class="mb-2 box-shadow-0 border p-1"
  >
    <b-overlay
      :show="loading"
      spinner-variant="warning"
      rounded="sm"
      no-fade
      variant="transparent"
      :opacity="0.5"
    >
      <!-- Filters -->
      <RetailReportBookingsFilter
        :search-text-filter="searchTextFilter"
        :booking-code-filter="bookingCodeFilter"
        :created-by-filter.sync="createdByFilter"
        :brand-filter.sync="brandFilter"
        :source-filter.sync="sourceFilter"
        :status-filter-by-user.sync="statusFilterByUser"
        :agency-code-filter.sync="agencyCodeFilter"
        :start-date-filter.sync="startDateFilter"
        :start-date-flight-filter.sync="startDateFlightFilter"
        :end-date-flight-filter.sync="endDateFlightFilter"
        :end-date-filter.sync="endDateFilter"
        :status-options="optionsStatus"
        :brand-options="optionsAirLine"
        :source-options="optionsSource"
        :empty-filter="isEmptyFilter"
        :is-active-filter.sync="isActiveFilter"
        @fetch-data="refetchData"
        @updateSearchTextFilter="(value) => updateSearchText(value)"
        @updateBookingCodeFilter="(value) => updateBookingCode(value)"
        @reset="clearFilter"
      />

      <!-- Table Container Card -->
      <b-table
        ref="refReservationListTable"
        style="max-height: 70vh"
        :sticky-header="true"
        :items="retailReportCustomer"
        :striped="true"
        class="position-relative p-0 rounded"
        :hover="true"
        :borderless="true"
        :fields="tableColumns"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        responsive
        no-border-collapse
        show-empty
        :empty-text="$t('noMatchingResult')"
        small
      >
        <!-- ANCHOR - Column Name -->
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $t(`reservation.${data.label}`) }}
          </span>
        </template>

        <!-- ANCHOR - Row Details -->
        <template #row-details="row">
          <!-- ANCHOR - roundTripBooking -->
          <b-card
            v-if="row.item.roundtripBooking"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Chuyến khứ hồi:
                </span>
              </div>
            </template>
            <b-table
              striped
              thead-class="d-none"
              table-class=""
              class="position-relative p-0 rounded mb-0"
              borderless
              responsive
              no-border-collapse
              :items="[row.item.roundtripBooking]"
              :fields="tableColumns"
              :empty-text="$t('noMatchingResult')"
              small
            >
              <template #cell(index)>
                <div class="text-nowrap mr-3" />
              </template>

              <template #cell(source)="data">
                <div class="text-nowrap d-flex-center">
                  <span
                    class="h5 mb-0 py-25 px-50"
                    :style="resolveBorderColorByAirline(data.item.source, 900)"
                  >
                    {{ data.item.source }}
                  </span>
                </div>
              </template>

              <template #cell(bookingCode)="data">
                <div class="text-center ml-2">
                  <b-link
                    :disabled="!canAccess('booking.detailBooking')"
                    :to="{ name: 'apps-reservations-modify', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.bookingCode }}
                  </b-link>
                  <b-button
                    v-if="data.item.roundtripBooking"
                    size="sm"
                    variant="info"
                    class="font-medium-2 py-25 px-50 font-weight-bold"
                    @click="data.toggleDetails"
                  >
                    <!-- {{ data.detailsShowing ? 'Hide' : 'Show' }} -->
                    {{ data.item.roundtripBooking.bookingCode }}
                  </b-button>
                </div>
              </template>

              <template #cell(status)="data">
                <div class=" ml-3">
                  <b-badge
                    :variant="
                      `light-${resolveBookingStatusVariant(
                        data.item.status|| data.item.bookingStatus,
                      )}`
                    "
                    :class="`badge-glow round text-uppercase`"
                  >
                    {{ $t(`reservation.${data.item.status}`) }}
                  </b-badge>
                  <div>
                    <small v-if="data.item.timeHold && data.item.status.toUpperCase() !== 'PAID'">
                      ({{ convertISODateTime(data.item.timeHold).time }}
                      {{ convertISODateTime(data.item.timeHold).date }})
                    </small>
                  </div>
                </div>
              </template>

              <template #cell(passenger)="data">
                <div
                  v-for="(pax, index) of data.item.paxLists"
                  :key="index"
                  class="ml-2"
                >
                  <small class="text-body-3 text-nowrap">
                    {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
                  </small>
                </div>
              </template>

              <template #cell(booker)="data">
                <div
                  :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
                  class="w-100 h-100"
                >
                  <small class="text-info font-weight-bold text-body-3 text-nowrap">
                    {{ data.item.createdBy.username }}
                  </small>
                  <b-tooltip
                    :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="info"
                  >
                    <div>
                      {{ data.item.createdBy.lastName }}
                      {{ data.item.createdBy.firstName }}
                      ({{ data.item.createdBy.type }})
                    </div>
                    <div>
                      {{ data.item.createdBy.employeeCode }}
                    </div>
                  </b-tooltip>
                </div>
              </template>

              <template #cell(flight)="data">
                <div v-if="data.item.bookingRequest">
                  <div
                    v-for="(trip, index) of resolveBookingRequest(data.item.bookingRequest.itineraries)"
                    :key="index"
                    class="text-nowrap text-body-3"
                  >
                    <div
                      v-for="(segment,indexSegment) in trip.segments"
                      :key="indexSegment"
                      class="d-flex flex-nowrap my-50"
                    >
                      <IAmLogoAirline
                        :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                        size="xs"
                        rounded
                        custom-class="mr-50"
                      />
                      <span>
                        {{ segment.devSegmentIndex }}
                        {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}
                        {{ segment.bookingClass }}
                        {{ convertISODateTime(segment.departureTime).dayMonth }}
                        {{ `${segment.departure}${segment.arrival}` }}
                        {{ convertISODateTime(segment.departureTime).hourMin }}
                        {{ convertISODateTime(segment.arrivalTime).hourMin }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div
                    v-for="(trip, index) of data.item.itineraries.filter(trip => trip.some(segment => segment.status !== 'HX'))"
                    :key="index"
                    class="text-nowrap text-body-3"
                  >
                    <div
                      v-for="(segment,indexSegment) in trip"
                      :key="indexSegment"
                      class="d-flex flex-nowrap my-50"
                    >
                      <IAmLogoAirline
                        v-if="segment.airline"
                        :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                        size="xs"
                        rounded
                        custom-class="mr-50"
                      />
                      <span>
                        {{ convertShortTrip(segment) }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(createdAt)="data">
                <b-media no-body>
                  <b-media-body>
                    <h6 class="mb-0">
                      {{ convertISODateTime(data.item.dateCreate).time }}
                    </h6>
                    <h6 class="mb-0">
                      {{ convertISODateTime(data.item.dateCreate).date }}
                    </h6>
                  </b-media-body>
                </b-media>
              </template>

              <template #cell(note)="data">
                <div
                  class="two-line-ellipsis"
                  style=""
                >
                  {{ data.item.note }}
                </div>
              </template>
            </b-table>
          </b-card>

          <b-card
            v-if="!isEmpty(row.item.crossReferenceDetails)"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Số vé liên quan:
                </span>
              </div>
            </template>

            <b-table
              striped
              thead-class="d-none"
              table-class=""
              class="p-0 rounded mb-0"
              borderless
              no-border-collapse
              :items="row.item.crossReferenceDetails"
              :fields="['bookingCode']"
              :empty-text="$t('noMatchingResult')"
              small
            >
              <template #cell(bookingCode)="data">
                <div
                  class=""
                  style="margin-left: 150px;"
                >
                  <b-link
                    v-if="data.item.id"
                    :disabled="!canAccess('booking.detailBooking')"
                    :to="{ name: 'apps-reservations-modify', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.pnrNumber }}
                  </b-link>

                  <div
                    v-else
                    class="font-weight-bold d-block text-nowrap text-info pb-0"
                  >
                    {{ data.item.pnrNumber }}
                  </div>
                </div>
              </template>
            </b-table>
          </b-card>

          <!-- ANCHOR - Vé rẻ canh được -->
          <b-card
            v-if="row.item.lowFareTrackingTasks && row.item.lowFareTrackingTasks.some(i => i.result)"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Danh sách mã đặt chỗ từ canh vé thành công:
                </span>
              </div>
            </template>

            <b-table
              striped
              thead-class="d-none"
              table-class=""
              class="p-0 rounded mb-0"
              borderless
              no-border-collapse
              :items="row.item.lowFareTrackingTasks.filter(vr => vr.result).map(task => JSON.parse(task.result).lowFareTrackedBookings.map(ve => ({...ve})))"
              :fields="['bookingCode']"
              :empty-text="$t('noMatchingResult')"
              small
            >
              <template #cell(bookingCode)="{item}">
                <div
                  v-for="(datcho, mIndex) of item"
                  :key="mIndex"
                  class=""
                  style="margin-left: 150px;"
                >
                  <b-link
                    :disabled="!canAccess('booking.detailBooking')"
                    :to="{ name: 'apps-reservations-modify', params: { id: datcho.id } }"
                    class="font-weight-bold d-block text-nowrap text-info py-50"
                  >
                    <div>
                      {{ datcho.pnr }}
                    </div>
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>

          <!-- ANCHOR - Số vé đã thực hiện canh ra vé này -->
          <b-card
            v-if="row.item.lowFareTrackingFrom"
            class="border-warning"
            header-bg-variant="light-info"
            header-class="py-50"
            no-body
          >
            <template #header>
              <div>
                <span class="text-airline font-weight-bolder">
                  Mã đặt chỗ đã thực hiện canh vé này:
                </span>
              </div>
            </template>
            <b-link
              :disabled="!canAccess('booking.detailBooking')"
              :to="{ name: 'apps-reservations-modify', params: { id: row.item.lowFareTrackingFrom.bookingId } }"
              style="margin-left: 150px;"
              class="font-weight-bold d-block text-nowrap text-info py-50"
            >
              <div>
                {{ row.item.lowFareTrackingFrom.bookingCode }}
              </div>
            </b-link>
          </b-card>
        </template>

        <template #cell(index)="data">
          <div class="text-nowrap">
            <span
              class="align-text-top text-capitalize font-weight-bold d-flex-center"
              style="color: #333333"
            >
              {{ ((currentPage - 1) * sizePerPage) + data.index + 1 }}
            </span>
          </div>
        </template>

        <template #cell(source)="data">
          <div class="text-nowrap d-flex-center">
            <span
              class="h5 mb-0 py-25 px-50 fw-900"
              :style="resolveBorderColorByAirline(data.item.source, 900)"
            >
              {{ data.item.source }}
            </span>
          </div>
        </template>

        <template #cell(bookingCode)="data">
          <b-link
            :disabled="!canAccess('booking.detailBooking')"
            :to="{ name: 'apps-reservations-modify', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap text-info pb-25"
          >
            {{ data.item.bookingCode }}
          </b-link>

          <!-- TODO - || (data.item.multiCityBookings && !isEmpty(data.item.multiCityBookings)) -->
          <b-button
            v-if="data.item.roundtripBooking || !isEmpty(data.item.crossReferenceDetails) || (data.item.lowFareTrackingTasks && data.item.lowFareTrackingTasks.some(i => i.result)) || data.item.lowFareTrackingFrom"
            v-b-tooltip.hover.v-warning.window.right
            :title="isMobileView ? '' : data.item.roundtripBooking ? 'Chuyến khứ hồi' : 'Vé liên quan'"
            size="sm"
            :variant="data.item.roundtripBooking ? 'flat-warning' : 'outline-warning'"
            class="font-medium-1 py-25 d-flex"
            :class="data.item.roundtripBooking ? 'px-0' : 'px-2'"
            @click="data.toggleDetails"
          >
            <span
              v-if="data.item.roundtripBooking"
              class="mr-25"
            >
              {{ data.item.roundtripBooking.bookingCode }}
            </span>

            <feather-icon
              v-if="data.detailsShowing"
              icon="ChevronUpIcon"
              size="16"
            />

            <feather-icon
              v-else
              icon="ChevronDownIcon"
              size="16"
            />
          </b-button>
        </template>

        <template #cell(status)="data">
          <b-badge
            :variant="
              `light-${resolveBookingStatusVariant(
                data.item.status|| data.item.bookingStatus,
              )}`
            "
            :class="`badge-glow round text-uppercase`"
          >
            {{ $t(`reservation.${data.item.status}`) }}
          </b-badge>
          <div>
            <small v-if="data.item.timeHold && data.item.status.toUpperCase() !== 'PAID'">
              ({{ convertISODateTime(data.item.timeHold).time }}
              {{ convertISODateTime(data.item.timeHold).date }})
            </small>
          </div>
        </template>

        <template #cell(rebook)="data">
          <div class="d-flex justify-content-center flex-nowrap">
            <b-button
              v-if="checkCanRebook(data.item)"
              :id="`btn-rebook-${data.item.id}`"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-warning.window
              variant="outline-warning"
              :title="isMobileView ? '' : 'Rebook'"
              class="btn-icon p-50"
              @click="showRebookModal(data.item)"
            >
              <feather-icon
                icon="RotateCwIcon"
                size="22"
              />
            </b-button>
            <b-button
              v-else-if="data.item.status === 'DRAFT'"
              :id="`btn-submit-booking-${data.item.id}`"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-warning.window
              variant="outline-info"
              :title="isMobileView ? '' : $t('reservation.submitBooking')"
              class="btn-icon p-50"
              @click="showSubmitBookingModal(data.item)"
            >
              <feather-icon
                icon="ShareIcon"
                size="22"
              />
            </b-button>
          </div>
        </template>

        <template #cell(passenger)="data">
          <div
            v-for="(pax, index) of data.item.paxLists"
            :key="index"
          >
            <small class="text-body-3 text-nowrap">
              {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
            </small>
          </div>
        </template>

        <template #cell(booker)="data">
          <div
            :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
            class="w-100 h-100"
          >
            <small class="text-info font-weight-bold text-body-3 text-nowrap">
              {{ data.item.createdBy.username.toUpperCase() }}
            </small>
            <b-tooltip
              :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
              triggers="hover focus"
              placement="top"
              boundary="viewport"
              variant="info"
            >
              <div>
                {{ data.item.createdBy.lastName }}
                {{ data.item.createdBy.firstName }}
                ({{ data.item.createdBy.type }})
              </div>
              <div>
                {{ data.item.createdBy.employeeCode }}
              </div>
            </b-tooltip>
          </div>
        </template>
        <template #cell(price)="data">
          <div
            class="text-right font-weight-bolder"
            style="color: #00558f"
          >
            {{ formatCurrency((data.item.totalAmountTicket) || 0) }}
          </div>
        </template>

        <template #cell(flight)="data">
          <div v-if="data.item.bookingRequest">
            <div
              v-for="(trip, index) of resolveBookingRequest(data.item.bookingRequest.itineraries)"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment,indexSegment) in trip.segments"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ segment.devSegmentIndex }}
                  {{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}
                  {{ segment.bookingClass }}
                  {{ convertISODateTime(segment.departureTime).dayMonth }}
                  {{ `${segment.departure}${segment.arrival}` }}
                  {{ convertISODateTime(segment.departureTime).hourMin }}
                  {{ convertISODateTime(segment.arrivalTime).hourMin }}
                </span>
              </div>
            </div>
          </div>

          <div v-else>
            <div
              v-for="(trip, index) of data.item.itineraries.filter(trip => trip.some(segment => segment.status !== 'HX'))"
              :key="index"
              class="text-nowrap text-body-3"
            >
              <div
                v-for="(segment,indexSegment) in trip"
                :key="indexSegment"
                class="d-flex flex-nowrap my-50"
              >
                <IAmLogoAirline
                  v-if="segment.airline"
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="xs"
                  rounded
                  custom-class="mr-50"
                />
                <span>
                  {{ convertShortTrip(segment) }}
                </span>
              </div>
            </div>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex-center flex-nowrap gap-1">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              variant="light"
              :title="isMobileView ? '' : (data.item.isActive ? $t('reservation.hideBooking') : $t('reservation.showBooking'))"
              class="btn-icon p-50"
              @click="showConfirmToggleHideBooking(data.item, !data.item.isActive)"
            >
              <feather-icon
                v-if="data.item.isActive"
                style="color: #efad02"
                icon="EyeIcon"
                size="18"
              />
              <feather-icon
                v-else
                style="color: #efad02"
                icon="EyeOffIcon"
                size="18"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              variant="light"
              :title="isMobileView ? '' : $t('reservation.exportTicket')"
              class="btn-icon p-50"
              @click="showPopupTicket(data.item)"
            >
              <img src="@icons/plane-ticket-blue.svg">
            </b-button>

            <b-button
              id="button-change-note"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              v-b-modal.reservation-modify-change-note-modal
              :title="isMobileView ? '' : 'Chỉnh sửa ghi chú'"
              variant="light"
              class="btn-icon p-50"
              @click="editNote(data.item, $event.target)"
            >
              <img src="@icons/edit.svg">
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              variant="light"
              :title="isMobileView ? '' : $t('reservation.exportSms')"
              class="btn-icon p-50"
              @click="showPopupSms(data.item)"
            >
              <img src="@icons/SMS.svg">
            </b-button>

            <b-button
              v-if="isF3canSendMailAirline && data.item.status !== 'CANCEL' && canAccess('booking.sendEmail') && !data.item.isOutside"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              variant="light"
              :title="isMobileView ? '' : $t('reservation.sendEmail.title')"
              class="btn-icon p-50"
              @click="e => handleShowModalSendEmail(data.item, e)"
            >
              <img src="@icons/mail.svg">
            </b-button>

            <b-button
              v-if="data.item.ip"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              :variant="data.item.isBanned ? 'success' : 'danger'"
              :title="isMobileView ? '' : $t(`reservation.ban.${data.item.isBanned ? 'unban' : 'title'}`)"
              class="btn-icon p-50"
              @click="e => showBanOrUnbanModal(data.item)"
            >
              <feather-icon :icon="data.item.isBanned ? 'UnlockIcon' : 'SlashIcon'" />
            </b-button>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <b-media no-body>
            <b-media-body>
              <h6 class="mb-0">
                {{ convertISODateTime(data.item.dateCreate).time }}
              </h6>
              <h6 class="mb-0">
                {{ convertISODateTime(data.item.dateCreate).date }}
              </h6>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(note)="data">
          <div
            class="two-line-ellipsis"
            style=""
          >
            {{ data.item.note }}
            {{ data.item.autoIssueTime ? `Thời gian xuất vé tự động: ${convertISODateTime(data.item.autoIssueTime).dateTime}`: "" }}
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-start"
          >
            <span class="text-muted">
              {{ $t('paginationText.showing') }}
              <b-dropdown
                v-model="sizePerPage"
                variant="warning"
                :text="`${sizePerPage}`"
                class="per-page-selector d-inline-block p-0"
              >
                {{ dataMeta }}
                <b-dropdown-item
                  v-for="item in sizePerPageLgOptions"
                  :key="item"
                  @click="sizePerPage = item"
                >
                  {{ item }}
                </b-dropdown-item>
              </b-dropdown>
              {{ $t('tickets') }}
              <div>{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }} {{ dataMeta.of }} {{ $t('tickets') }}</div>
            </span>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalReservations"
              :per-page="sizePerPage"
              first-number
              last-number
              class="pagination-warning mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <NoteModal
        :reservation-data.sync="reservationData"
        @refetch-data="refetchData"
      />

      <ModalSms
        v-if="ticketData"
        :ticket-data="ticketData"
      />

      <ReBookModal
        v-if="ticketData"
        :booking-data="ticketData"
        @refetch-data="refetchData"
      />

      <!-- <FindBookingByPnrModal /> -->
      <SendEmailModal />

      <BanOrUnbanModal
        v-if="ticketData"
        :booking-data="ticketData"
        @refetch-data="refetchData"
      />

      <ModalSubmitDraft
        v-if="ticketData"
        :booking-data="ticketData"
        @refetch-booking-data="refetchData"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import { onUnmounted, ref, computed } from '@vue/composition-api'
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BLink,
  BMedia,
  BMediaBody,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

import { useRouter } from '@/@core/utils/utils'
import {
  resolveBookingStatusVariant,
  resolveGender,
  sizePerPageLgOptions,
  typeOfEmployeeOptions,
  typeOfEmployeeOptionsFilter,
} from '@/constants/selectOptions'
import store from '@/store'

import {
  convertISODateTime,
  convertShortTrip,
  formatCurrency,
  resolveAirlineFlightNumber,
  resolveBorderColorByAirline,
} from '@core/utils/filter'

import useToast from '@useToast'

import reservationStoreModule from '../reservation/reservationStoreModule'
import useReservationHandle from '../reservation/useReservationHandle'
import NoteModal from '../reservation/reservation-list/NoteModal.vue'
import ReBookModal from '../reservation/reservation-list/ReBookModal.vue'
import BanOrUnbanModal from './BanOrUnbanModal.vue'
import RetailReportBookingsFilter from './RetailReportBookingsFilter.vue'

export default {
  directives: { 'b-tooltip': VBTooltip },

  components: {
    NoteModal,
    RetailReportBookingsFilter,
    BMediaBody,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BOverlay,
    BPagination,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    ReBookModal,
    BanOrUnbanModal,
    ModalSms: () => import('@reservation/modals/ModalSms.vue'),
    SendEmailModal: () => import('@reservation/reservation-list/SendEmailModal.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    ModalSubmitDraft: () => import('@reservation/reservation-modify/components/detail/ModalSubmitDraft.vue'),
  },
  setup(props, { root }) {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    // Table Handlers
    const tableColumns = [
      { label: 'index', key: 'index' },
      { label: 'bookingInvoiceCode', key: 'bookingInvoiceCode' },
      { label: 'ip', key: 'ip' }, // thStyle: 'max-width: 10px'
      { label: 'source', key: 'source' }, // thStyle: 'max-width: 10px'
      { label: 'bookingCode', key: 'bookingCode' },
      { label: 'status', key: 'status' },
      { label: '', key: 'rebook' }, // thStyle: 'max-width: 10px'
      { label: 'passenger', key: 'passenger' },
      { label: 'flight', key: 'flight' },
      { label: 'price', key: 'price' }, // Anh tuấn bảo bỏ đi vì giá ko đúng
      { label: 'createdAt', key: 'createdAt', sortable: true },
      { label: 'booker', key: 'booker' },
      { label: 'action', key: 'action' },
      { label: 'note', key: 'note' },
    ]
    const {
      sizePerPage,
      currentPage,
      totalReservations,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refReservationListTable,

      // Extra Filters
      startDateFilter,
      endDateFilter,
      startDateFlightFilter,
      endDateFlightFilter,
      createdByFilter,
      brandFilter,
      sourceFilter,
      statusFilterByUser,
      searchTextFilter,
      bookingCodeFilter,
      isEmptyFilter,
      clearFilter,
      isActiveFilter,
      agencyCodeFilter,

      optionsAirLine,
      optionsSource,
      optionsStatus,
      loading,

      fetchBookingById,
      retailReportCustomer,
      refetchData,
      deActiveReservation, // for show/hide booking
      activeReservation, // for show/hide booking
      fetchAirportGroup,
      submitBooking,
    } = useReservationHandle()

    // Register module
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }
    })
    const { toastError } = useToast()
    const { router, route } = useRouter()
    const fullPath = computed(() => route.value.fullPath)
    const reservationData = ref({
      note: '',
    })

    if (isEmpty(store.getters['app-reservation/getAirportGroup'])) { fetchAirportGroup() }

    async function editNote(reservation, event) {
      await new Promise(resolve => {
        reservationData.value = reservation
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'reservation-modify-change-note-modal', event)
    }

    const ticketData = ref({})

    async function showPopupTicket(item) {
      await new Promise(resolve => {
        ticketData.value = item
        resolve()
      })
      // this.$root.$emit('bv::show::modal', 'id-modal-reservation-ticket')
      const { id, source, bookingCode } = ticketData.value
      const statusBooking = ['Hold', 'Paid', 'Cancel', 'Expired']
      const isPathHaveStatus = statusBooking.some(status => fullPath.value.includes(status))
      const path = `${!isPathHaveStatus ? '/apps/bookings/' : ''}${id || `${source}-${bookingCode}`}/ticket`
      const route = this.$router.resolve({ path })
      window.open(route.href)
    }

    async function showPopupSms(item) {
      await new Promise(resolve => {
        ticketData.value = item
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'id-modal-reservation-sms')
    }

    async function showBanOrUnbanModal(item) {
      await new Promise(resolve => {
        ticketData.value = item
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'modal-ban-or-unban-booking')
    }

    const currentTimeObj = new Date(new Date().toISOString())
    function checkTimeDifference(segment) {
      const departureTimeObj = new Date(convertISODateTime(segment.departureTime).ISOdatetime)
      return departureTimeObj.getTime() - currentTimeObj.getTime() < (4 * 60 * 60 * 1000)// 4 tiếng
    }
    const getEnableRebook = computed(() => store.getters['userStore/getEnableRebook'])
    // eslint-disable-next-line arrow-body-style
    const checkCanRebook = item => {
      // const minute = moment(item.timeHold).diff(moment(), 'minute')
      // const isHold10mins = ['HOLD'].includes(item.status) && minute > 0 && minute <= 10
      // return item.bookingRequest && (isHold10mins || ['CANCEL', 'EXPIRED'].includes(item.status)) && !['1G'].includes(item.source)
      return item.bookingRequest && (['CANCEL', 'EXPIRED', 'HOLD'].includes(item.status)) && !['1G'].includes(item.source) && getEnableRebook.value // && !(item?.rebooked || item?.isRebook)
    }

    async function showRebookModal(item) {
      if (item.status === 'EXPIRED' && ['1S', '1S_CTRL', '1G'].includes(item.source)) {
        const res = await fetchBookingById(item.id)
        if (res.data.status !== 'CANCEL') {
          toastError({
            title: 'Booking cũ vẫn còn hoạt động, không đặt lại được vé.',
            content: 'Đang chuyển sang booking cũ',
          })
          router.push({ name: 'apps-reservations-modify', params: { id: item.id } })
          return
        }
      }
      const bookingRequest = item.bookingRequest

      const isOverTime = checkTimeDifference(bookingRequest.itineraries[0].segments[0])

      if (isOverTime) {
        toastError('Đặt lại vé trong thời gian trước 4h khởi hành')
        return
      }

      await new Promise(resolve => {
        ticketData.value = item
        resolve()
      })

      this.$bvModal.show('modal-reservation-rebook-booking')
    }

    async function showSubmitBookingModal(reservationsData) {
      await new Promise(resolve => {
        ticketData.value = reservationsData
        resolve()
      })
      if (!reservationsData.roundtripBooking || ([reservationsData].concat(reservationsData.roundtripBooking).filter(item => !!item).some(booking => booking.status !== 'DRAFT'))) {
        const contentMsg = () => this.$createElement('div', { domProps: { innerHTML: `Bạn có chắc chắn muốn thực hiện đặt chỗ <code>${reservationsData.bookingCode}</code> không?` } })
        const titleMsg = () => this.$createElement('h4', { domProps: { innerHTML: root.$t('reservation.submitBooking') } })
        root.$bvModal
          .msgBoxConfirm(contentMsg(), {
            title: titleMsg(),
            size: 'sm',
            okVariant: 'danger',
            okTitle: root.$t('reservation.confirm'),
            cancelTitle: root.$t('modal.no'),
            cancelVariant: 'flat-dark',
            centered: true,
          })
          .then(async value => {
            if (value) {
              root.$bvModal.show('modal-api-loading')
              await submitBooking(reservationsData.id, {
                includeRTB: false,
              }).then(() => {
                refetchData()
              })
              root.$bvModal.hide('modal-api-loading')
            }
          })
      } else {
        this.$bvModal.show('modal-submit-draft')
      }
    }

    function updateSearchText(val) {
      searchTextFilter.value = val
    }

    function updateBookingCode(val) {
      bookingCodeFilter.value = val
    }

    function resolveBookingRequest(trips) {
      let indexSegment = 1
      return trips.map(trip => ({
        ...trip,
        segments: trip.segments.map(segment => {
          const data = {
            ...segment,
            devSegmentIndex: String(indexSegment),
          }

          indexSegment += 1
          return data
        }),
      }))
    }

    // ANCHOR handle show/hide booking
    function showConfirmToggleHideBooking({ bookingCode, id }, type) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: `<span>Bạn chắc chắn muốn ${type ? 'hiện' : 'ẩn'} mã đặt chỗ <em class='font-weight-bolder'>${bookingCode}</em>?</span>` } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'success',
          okTitle: this.$t('modal.confirm'),
          cancelTitle: this.$t('modal.cancel'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (type) {
              activeReservation(id, 'list')
            } else {
              deActiveReservation(id, 'list')
            }
          }
        })
    }

    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    // F3 (BE hidefee dang ko check), sendMailAirline = false ko hiển thị nút gửi mail
    const isF3canSendMailAirline = computed(() => {
      if (isRoleF3.value && store.getters['userStore/getAgencyData']?.sendMailAirline === false) {
        return false
      }
      return true
    })

    function handleShowModalSendEmail(bookingData, event) {
      if (bookingData.source === 'QH' && bookingData.status !== 'PAID') {
        toastError({ title: 'Vui lòng thanh toán vé để thực hiện !' })
        return
      }

      store.dispatch('app-reservation/setBookingData', bookingData)

      this.$root.$emit(
        'bv::show::modal',
        'id-modal-reservation-send-email',
        event,
      )
    }

    return {
      retailReportCustomer,
      convertISODateTime,
      formatCurrency,
      isEmpty,
      tableColumns,
      sizePerPage,
      currentPage,
      totalReservations,
      dataMeta,
      sizePerPageLgOptions,
      sortBy,
      isSortDirDesc,
      refReservationListTable,
      refetchData,
      deActiveReservation,
      activeReservation,

      // select Options
      typeOfEmployeeOptions,
      typeOfEmployeeOptionsFilter,
      resolveGender,
      optionsStatus,
      optionsAirLine,
      optionsSource,

      // Extra Filters
      startDateFilter,
      endDateFilter,
      startDateFlightFilter,
      endDateFlightFilter,
      createdByFilter,
      brandFilter,
      sourceFilter,
      statusFilterByUser,
      searchTextFilter,
      bookingCodeFilter,
      isEmptyFilter,
      clearFilter,
      reservationData,
      editNote,
      ticketData,
      showPopupTicket,
      showPopupSms,
      loading,
      updateSearchText,
      updateBookingCode,
      resolveBookingStatusVariant,
      convertShortTrip,

      checkCanRebook,
      showRebookModal,
      showSubmitBookingModal,
      resolveBookingRequest,
      showConfirmToggleHideBooking,
      isActiveFilter,
      resolveBorderColorByAirline,

      isF3canSendMailAirline,
      handleShowModalSendEmail,
      showBanOrUnbanModal,
      agencyCodeFilter,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  button {
    padding: 4px 16px;
    margin-bottom: 4px;
  }
}
.two-line-ellipsis {
  overflow: hidden;
  max-width: 240px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
